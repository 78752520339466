import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Badge from 'shopper/components/Badge';
import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import { sendEvent } from 'lib/gtag';
import { toLandingPageLink } from 'lib/links';
import { getFromLocalStorage, setLocalStorage } from 'lib/localStorage';

import LOCAL_STORAGE from 'constants/localStorage';

const gaEventAction = 'featured_landing_tab';

const FeaturedLandingLink = ({
  gaEventCategory,
  landingName,
  landingSlug,
  landingType,
}) => {
  const [hasClicked, setHasClicked] = useState(false);

  useEffect(() => {
    const clicked = getFromLocalStorage(
      LOCAL_STORAGE.IS_BLACK_FRIDAY_BUTTON_ALREADY_CLICKED
    );

    if (clicked) {
      setHasClicked(true);
    }
  }, [landingSlug]);

  const handleClick = () => {
    if (!hasClicked) {
      setHasClicked(true);
      setLocalStorage(
        LOCAL_STORAGE.IS_BLACK_FRIDAY_BUTTON_ALREADY_CLICKED,
        'true'
      );
    }
    sendEvent({
      action: gaEventAction,
      category: gaEventCategory,
    });
  };

  return (
    <NextLink
      href={toLandingPageLink(landingSlug, landingType)}
      prefetch={false}
      passHref
    >
      <Button
        as="a"
        className="relative ml-1 mr-2 hidden xl:flex"
        data-test-selector="featured-landing-tab"
        iconLeft={
          landingSlug === 'black-friday' && <Icon name="promobit-filled" />
        }
        size="size4"
        title={landingName}
        type="neutral-ghost"
        onClick={handleClick}
      >
        {landingName}
        {!hasClicked && (
          <Badge
            className="absolute right-0 top-1"
            shape="dot"
            type="highlight"
          />
        )}
      </Button>
    </NextLink>
  );
};

FeaturedLandingLink.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
  landingName: PropTypes.string.isRequired,
  landingSlug: PropTypes.string.isRequired,
  landingType: PropTypes.string.isRequired,
};

export default FeaturedLandingLink;
